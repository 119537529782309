<template>
  <div class="home">
    <Header></Header>
    <div class="warp">
      <wd-swipe>
        <wd-swipe-item v-for="item in banners" :key="item">
          <wd-img
            width="100%"
            height="180"
            fit="fill"
            :src="item"
            mode="aspectFill"
          />
        </wd-swipe-item>
      </wd-swipe>
      <!-- 四个 -->
      <div class="plr20">
        <div class="bgwhite t2 mt20 flex_around flex_items ptb20 bbe9s">
          <div
            v-for="(item, index) in tools"
            :key="index"
            class="flex_column flex_items"
            @click="wotiao(item.t1)"
          >
            <wd-img
              class=""
              width="60"
              height="40"
              fit="contain"
              :src="item.url"
            />
            <span class="f22 mt10">{{ item.t1 }}</span>
          </div>
        </div>
        <div class="flex_around flex_items ptb20 bgwhite">
          <div class="flex_start flex_items mr20">
            <wd-img
              :src="imgUrl + 'home/gongren.png'"
              width="26"
              height="26"
              fit="cover"
              class="mr5 tool2"
            />
            <span class="f16" style="color: #8d8d8d">专业工人 经验丰富</span>
          </div>
          <div class="flex_start flex_items mr20">
            <wd-img
              :src="imgUrl + 'home/zengxiang.png'"
              width="26"
              height="26"
              fit="cover"
              class="mr5"
            />
            <span class="f16" style="color: #8d8d8d">施工0增项</span>
          </div>
          <div class="flex_start flex_items">
            <wd-img
              :src="imgUrl + 'home/shigong.png'"
              width="26"
              height="26"
              fit="cover"
              class=""
            />
            <span class="f16" style="color: #8d8d8d">施工延期赔付</span>
          </div>
        </div>
      </div>
      <!-- 优势 -->
      <div class="mt30 bgwhite">
        <div class="flex_center flex_items">
          <p class="bold f32 mt30" style="height: 35px; line-height: 35px">
            益鸟美居 核心优势
          </p>
        </div>
        <div class="flex_column flex_items mt30">
          <wd-img
            fit="contain"
            :src="imgUrl + 'home/mendian1.png'"
            width="100%"
          />
          <wd-img
            fit="contain"
            :src="imgUrl + 'home/gongren-421.png'"
            width="100%"
          />
          <wd-img
            fit="contain"
            :src="imgUrl + 'home/xitong1.png'"
            width="100%"
          />
          <wd-img
            fit="contain"
            :src="imgUrl + 'home/jishu1.png'"
            width="100%"
          />
        </div>
      </div>
      <!-- 预约 -->
      <div class="p20">
        <div
          class="plr20 flex_column flex_items bgwhite pb30"
          style="border-radius: 20px"
        >
          <div class="flex_items flex_column mt20">
            <p class="bold f28">房屋维修改造翻新具体需要多少钱</p>
            <p class="f20 mt10" style="color: #8d8d8d">
              自营门店 · 专业工人 · 店长上门检测 · 15分钟极速响应
            </p>
          </div>
          <div class="flex_column mt20" style="width: 100%">
            <div class="flex_start pos" style="width: 100%">
              <input
                type="text"
                placeholder="姓名"
                v-model="info.user"
                class="f24 p5"
                @click="show = true"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                "
              />
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <!-- 电话 -->
            <div class="flex_start pos mt10" style="width: 100%">
              <input
                type="text"
                placeholder="联系电话"
                v-model="info.phone"
                class="f24 p5"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                "
              />
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <!-- 房屋问题 -->
            <div class="flex_start pos mt10" style="width: 100%">
              <div
                type="text"
                class="f24 p5 f-left"
                @click="pickerVisible = true"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                  line-height: 35px;
                  color: #8d8d8d;
                "
              >
                {{ result }}
              </div>
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <wd-button
              @click="onsubmit"
              block
              size="large"
              class="mt30"
              style="height: 45px; background: #007B46; border-radius: 5px"
              >立即预约</wd-button
            >
          </div>
        </div>
      </div>
      <!-- 服务流程 -->
      <div>
        <wd-img :src="imgUrl + 'home/opp.png'" fit="cover" />
      </div>
      <!-- 专利 -->
      <div class="zhuan pb20">
        <div class="flex_center flex_items">
          <div class="flex_items flex_column mt40">
            <p class="bold f32" style="color: white">益鸟专利优势</p>
          </div>
        </div>
        <div class="mt60 plr20">
          <wd-swipe @change="videochange">
            <wd-swipe-item v-for="(item, index) in videos" :key="index">
              <div>
                <div class="flex_column flex_items">
                  <video-player
                    x5-video-player-type="h5-page"
                    ref="videoPlayer"
                    class="video-player vjs-custom-skin shipintu"
                    :playsinline="true"
                    :options="playerOptions"
                  />
                </div>
                <p class="mt35 bold f30 f-left" style="color: #f5ca91">
                  {{ item.title }}
                </p>
                <div class="pos mt10 f22 f-left" style="color: white">
                  {{ item.second_level }}{{ item.three_levels }}
                  <div class="abs hangse"></div>
                </div>
                <p class="f-left f18 mt10" style="color: white">项目特点:</p>
                <p class="f-left f18 mt10" style="color: #f5ca91">
                  {{ item.characteristic }}
                </p>
                <p class="f-left f18 mt20" style="color: white">明管工艺:</p>
                <p class="f-left f18 mt10" style="color: #f5ca91">
                  {{ item.technology }}
                </p>
              </div>
            </wd-swipe-item>
          </wd-swipe>
        </div>
      </div>
      <!-- 业主口碑 -->
      <div>
        <wd-img :src="imgUrl + 'home/koubei.png'" fit="cover" />
      </div>
      <!-- 预约 -->
      <div class="p20">
        <div
          class="plr20 flex_column flex_items bgwhite pb30"
          style="border-radius: 20px"
        >
          <div class="flex_items flex_column mt20">
            <p class="bold f28">房屋维修改造翻新具体需要多少钱</p>
            <p class="f20 mt10" style="color: #8d8d8d">
              自营门店 · 专业工人 · 店长上门检测 · 15分钟极速响应
            </p>
          </div>
          <div class="flex_column mt20" style="width: 100%">
            <div class="flex_start pos" style="width: 100%">
              <input
                type="text"
                placeholder="姓名"
                v-model="info.user"
                class="f24 p5"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                "
              />
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <!-- 电话 -->
            <div class="flex_start pos mt10" style="width: 100%">
              <input
                type="text"
                placeholder="联系电话"
                v-model="info.phone"
                class="f24 p5"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                "
              />
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <!-- 房屋问题 -->
            <div class="flex_start pos mt10" style="width: 100%">
              <div
                type="text"
                class="f24 p5 f-left"
                @click="pickerVisible = true"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                  line-height: 35px;
                  color: #8d8d8d;
                "
              >
                {{ result }}
              </div>
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <wd-button
              @click="onsubmit"
              block
              size="large"
              class="mt30"
              style="height: 45px; background: #007B46; border-radius: 5px"
              >立即预约</wd-button
            >
          </div>
        </div>
      </div>
      <!-- 案例展示 -->
      <div class="bgwhite mt30 plr20 pt20 pb40">
        <div class="flex_items flex_column mt20">
          <p class="bold f32">案例展示</p>
        </div>
        <!-- <div class="flex_warp flex_items mt30">
          <div
            class="flex_column mr20 mt20 mm"
            v-for="(item, index) in anlis"
            :key="index"
            style="width: 48%"
            @click="casedetail(item.id)"
          >
            <wd-img :src="tuhose + item.url" fit="cover" mode="aspectFill" />
            <p class="bold f24 mt15 f-left">{{ item.title }}</p>
            <p class="f22 mt10 flex_items f-left" style="color: #8d8d8d">
              {{ item.name }}&nbsp;|&nbsp;{{ item.area }}m²&nbsp;|&nbsp;{{
                item.construction_time
              }}
            </p>
          </div>
        </div> -->

        <div class="casePink_code">
          <div
            class="item"
            v-for="(item, index) in anlis"
            :key="index"
            @click="casedetail(item.id)"
          >
            <img :src="tuhose + item.url" alt="" mode="aspectFill" />
            <p class="bold f24 mt15 f-left">{{ item.title }}</p>
            <p class="f22 mt10 flex_items f-left" style="color: #8d8d8d">
              {{ item.name }}&nbsp;|&nbsp;{{ item.area }}m²&nbsp;|&nbsp;{{
                item.construction_time
              }}
            </p>
          </div>
        </div>
        <wd-button
          type="info"
          block
          class="mt30 mu"
          @click="morecase"
          style="
            height: 40px;
            font-size: 16px !important;
            color: #666666;
            background: white;
          "
          >查看更多</wd-button
        >
      </div>
      <!-- 正在施工工地 -->
      <div class="pb30 zs" style="height: 100px">
        <div class="flex_items flex_column">
          <p class="bold f32 mt40">正在施工工地</p>
        </div>
      </div>
      <div class="yilan pos" style="height: 190px">
        <wd-img
          :src="imgUrl + 'home/yilanbiao.png'"
          fit="cover"
          class="abs"
          style="top: -40px; z-index: 0; width: 100%; left: 0"
        />
        <p
          class="abs bold f20"
          style="
            color: white;
            top: -32px;
            left: 0;
            text-align: center;
            width: 100%;
          "
        >
          施工工地一览表
        </p>
        <div class="marquee-wrap">
          <div class="flex_around flex_items plr20">
            <span class="f20 bold" style="width: 56px">小区名称</span>
            <span class="f20 bold" style="width: 56px">项目类型</span>
            <span class="f20 bold" style="width: 56px">项目名称</span>
            <span class="f20 bold" style="width: 56px">施工面积</span>
            <span class="f20 bold" style="width: 56px">状态</span>
          </div>
          <ul
            class="marquee-list"
            :class="{ 'animate-up': animateUp }"
            style="margin-top: 20px"
          >
            <li
              v-for="(item, index) in listData"
              :key="index"
              class="flex_around flex_items plr20"
            >
              <span class="wenwen f18" style="width: 56px">{{
                item.residential_quarters
              }}</span>
              <span class="wenwen f18" style="width: 56px">{{
                item.pro_id_title
              }}</span>
              <span class="wenwen f18" style="width: 56px">{{
                item.pro_id1_title
              }}</span>
              <span class="wenwen f18" style="width: 56px"
                >{{ item.area }}m²</span
              >
              <span class="wenwen f18" style="width: 56px">{{
                item.state
              }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- 数据 -->
      <div class="pb30 bgwhite">
        <wd-img :src="imgUrl + 'home/shuju123.jpg'" fit="cover" />
      </div>
      <div v-if="pickData.length != 0">
        <VuePicker
          :data="pickData"
          :showToolbar="true"
          @confirm="confirm"
          :visible.sync="pickerVisible"
        />
      </div>
    </div>
    <div class="bgwhite pt20">
      <Footer />
    </div>
  </div>
</template>

<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
import VuePicker from "vue-pickers";
let tdata = [];
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      tuhose: this.util.tuhose,
      banners: [
        this.util.imgUrl + "home/banner1.png",
        this.util.imgUrl + "home/banner2.png"
      ],
      tools: [
        { url: this.util.imgUrl + "home/jugai.png", t1: "局部改造" },
        { url: this.util.imgUrl + "home/qiangmian.png", t1: "墙面翻新" },
        { url: this.util.imgUrl + "home/fangshui.png", t1: "防水补漏" },
        { url: this.util.imgUrl + "home/weixiu.png", t1: "维修安装" }
      ],
      wens: [],
      info: {
        user: "",
        phone: ""
      },
      anlis: [],
      // 视频
      videos: [],
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "6:4", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "" // url地址
          }
        ],
        hls: true,
        poster: this.util.imgUrl + "home/shipin.png", // 你的封面地址
        width: "100%", // 播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //时间分割线
          durationDisplay: true, //总时间
          remainingTimeDisplay: false, //剩余播放时间
          progressControl: true, // 进度条
          fullscreenToggle: true // 全屏按钮
        }
      },
      // 滚动
      animateUp: false,
      listData: [],
      // picker
      pickerVisible: false,
      pickData: [tdata],
      result: "选择房屋问题"
    };
  },
  mounted() {
    this.$store.commit("setpage", "首页");
    this.timer = setInterval(this.scrollAnimate, 1000);
    this.getData();
  },
  methods: {
    getData() {
      this.util.get(this.HOST + "/api/index", {}).then(res => {
        console.log(res);
        if (res.errcode == 200) {
          this.videos = res.data.video;
          this.anlis = res.data.examples;
          this.listData = res.data.stores;
          this.playerOptions.sources[0].src = res.data.video[0].url_path;
          this.getlei();
        }
      });
    },
    getlei() {
      this.util.get(this.HOST + "/api/service_category/", {}).then(res => {
        console.log(res);
        if (res.errcode == 200) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].value = res.data[i].category_id;
            res.data[i].label = res.data[i].name;
            if (tdata.length <= 3) {
              tdata.push({
                value: res.data[i].category_id,
                label: res.data[i].name
              });
            }
          }
          this.loading = false;
        }
      });
    },
    wotiao(name) {
      let name2 = "";
      if (name == "局部改造") {
        name2 = "jubu";
      } else if (name == "墙面翻新") {
        name2 = "wall";
      } else if (name == "防水补漏") {
        name2 = "fang";
      } else {
        name2 = "weixiu";
      }
      this.$router.push({
        name: name2,
        params: {}
      });
    },
    casedetail(id) {
      this.$router.push({
        path: "/casedetail",
        query: {
          id: id
        }
      });
    },
    morecase() {
      this.$router.push({
        path: "/yicase",
        query: {}
      });
    },
    onsubmit() {
      if (this.info.phone == "") {
        this.$toast.warning("请填写电话号码");
        return false;
      }
      this.util
        .getinfo(this.HOST + "/api/indexForm", {
          source_type: 1,
          name: this.info.user,
          mobile: this.info.phone,
          category_id: "",
          describe: "",
          area: ""
        })
        .then(res => {
          console.log(res);
          if (res.errcode == 200) {
            this.$toast.success("提交成功");
          } else {
            this.$toast.error(res.msg);
          }
        });
    },
    videochange(val) {
      this.playerOptions.sources[0].src = this.videos[val].url_path;
    },
    scrollAnimate() {
      this.animateUp = true;
      setTimeout(() => {
        this.listData.push(this.listData[0]);
        this.listData.shift();
        this.animateUp = false;
      }, 500);
    },
    confirm(res) {
      console.log(res);
      this.result = res[0].label;
      this.info.wen = res[0].value;
    }
  },
  components: {
    Header: header,
    Footer: footer,
    VuePicker
  }
};
</script>

<style>
.casePink_code {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.casePink_code .item {
  width: 48%;
  border: 1rpx solid red;
}
.casePink_code .item img {
  width: 100%;
}
.home {
  overflow: hidden;
}
.home .t2 {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.home .tool2 {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.home .wd-picker__field {
  padding-left: 10px !important;
}
.home .wd-picker__label {
  text-align: left;
  font-size: 24px !important;
  color: #8d8d8d;
}
.home .zhuan {
  background: url("https://imgaes.yiniaoweb.com/gw/mobile/home/beijing1.png")
    no-repeat;
  background-size: 100% 100%;
  background-position: 100% 100%;
  width: 100%;
}
.home .shipintu {
  width: 620px;
  /* height: 320px; */
}
.home .hangse {
  height: 12px;
  opacity: 0.7;
  background: #f5ca91;
  bottom: 0;
  left: 0;
}
.home .mm:nth-child(2),
.home .mm:nth-child(4) {
  margin-right: 0 !important;
}
.home .zs {
  background: url("https://imgaes.yiniaoweb.com/gw/mobile/home/cizhuanbeijing.png")
    no-repeat;
  background-size: cover;
  background-position: 100% 100%;
  width: 100%;
}
/* 滚动 */

.marquee-wrap .marquee-list {
  margin: 0;
  padding: 0;
}
.marquee-wrap {
  height: 150px;
  margin: 0;
  overflow: hidden;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 10;
  width: 100%;
}
.marquee-wrap .animate-up {
  transition: all 0.5s ease-in-out;
  transform: translateY(-40px);
}
.marquee-wrap li {
  /* padding-left: 23px; */
  box-sizing: border-box;
  /* width: 100%; */
  /* height: 100%; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  list-style: none;
  line-height: 40px;
  /* text-align: center; */
  color: #333;
  font-size: 14px;
  font-weight: 400;
}
.home .wenwen {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  width: 56px;
}
.home .pp {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: white;
}
.home .vjs-custom-skin > .video-js .vjs-control-bar {
  width: 90% !important;
  margin: 0 auto !important;
}
.wd-button__txt {
  font-size: 20px !important;
}
.mu .wd-button__txt {
  font-size: 26px !important;
}
</style>
