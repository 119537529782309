<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  created() {
    this.getyu();
  },
  methods: {
    getyu() {
      if (this._isMobile()) {
        console.log(this._isMobile(), "is mobile");
        console.log(window.location.host, "host");
        switch (window.location.host) {
          case "91yiniao.com":
            window.location.href = "https://m.yesbird.cn";
            break;
          case "www.91yiniao.com":
            window.location.href = "https://m.yesbird.cn";
            break;
          case "51yiniao.com":
            window.location.href = "https://m.yesbird.cn";
            break;
          case "www.51yiniao.com":
            window.location.href = "https://m.yesbird.cn";
            break;
          case "yesbird.com.cn":
            window.location.href = "https://m.yesbird.com.cn";
            break;
          case "www.yesbird.com.cn":
            window.location.href = "https://m.yesbird.com.cn";
            break;
          case "yesbird.cn":
            window.location.href = "https://m.yesbird.cn";
            break;
          case "www.yesbird.cn":
            window.location.href = "https://m.yesbird.cn";
            break;
          case "yesbird.net":
            window.location.href = "https://m.yesbird.net";
            break;
          case "www.yesbird.net":
            window.location.href = "https://m.yesbird.net";
            break;
        }
      } else {
        switch (window.location.host) {
          case "m.91yiniao.com":
            window.location.href = "https://yesbird.cn";
            break;
          case "m.51yiniao.com":
            window.location.href = "https://yesbird.cn";
            break;
          case "m.yesbird.com.cn":
            window.location.href = "https://yesbird.com.cn";
            break;
          case "m.yesbird.cn":
            window.location.href = "https://yesbird.cn";
            break;
          case "m.yesbird.net":
            window.location.href = "https://yesbird.net";
            break;
        }
      }
    },

    /*
    getyu() {
      this.util.get(this.HOST + "/api/reds", {}).then((res) => {
        if (res.errcode == 200) {
          this.getbanben(res.data.pc);
          // console.log(document.domain);
          //  console.log(window.location.host);

      }
      });
    },
    getbanben(link) {
      if (!this._isMobile()) {
        if(window.location.host=="m.91yiniao.com"){
          window.location.href = link;
        }else if(window.location.host=="m.sh.51yiniao.com"){
          window.location.href = 'http://sh.51yiniao.com/';
        }else{
           window.location.href = 'http://www.51yiniao.com/';
        }
      }
    },
    */
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    }
  },
  components: {}
};
</script>
<style>
@font-face {
  font-family: "JDZhengHT-Bold";
  src: url("https://static.360buyimg.com/bus/fonts/JDZhengHT/JDZhengHT-Bold.woff")
      format("woff"),
    /* chrome, firefox */
      url("https://static.360buyimg.com/bus/fonts/JDZhengHT/JDZhengHT-Bold.ttf")
      format("truetype"); /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  font-weight: normal;
  font-style: normal;
}
#app {
  font-family: "JDZhengHT-Bold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f5f5f5;
}
p {
  margin: 0;
  white-space: normal;
  word-break: break-word;
}
.wd-toast__msg {
  font-size: 22px !important;
  line-height: 15px !important;
}
</style>
