<template>
  <div class="footer">
    <wd-img :src="imgUrl + 'home/dibu1.png'" fit="cover" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl
    };
  }
};
</script>
