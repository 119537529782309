import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/style.css";
import Axios from "axios";
import qs from "qs";
import util from "@/util/util.js";
import WotDesign from "wot-design";
import "wot-design/lib/theme-default/index.css";
import "@/util/rem.js";
// 视频
import VideoPlayer from "vue-video-player";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
Vue.use(VideoPlayer);
// 使用picker组件
import { Picker } from "mint-ui";
Vue.component(Picker.name, Picker);

Vue.config.productionTip = false;
Vue.use(WotDesign);
Vue.prototype.$http = Axios;
Vue.prototype.$qs = qs;
Vue.prototype.util = util;
Vue.prototype.HOST = process.env.NODE_ENV === "production" ? "" : "/api";
/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
