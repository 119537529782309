import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "益鸟美居  益鸟维修，房屋维修+装修改造，真自营不外包"
    },
    component: Home
  },
  {
    path: "/jubu",
    name: "jubu",
    meta: {
      title: "益鸟维修-局部改造"
    },
    component: () => import(/* webpackChunkName: "about" */ "../views/jubu.vue")
  },
  {
    path: "/wall",
    name: "wall",
    meta: {
      title: "益鸟维修-墙面翻新"
    },
    component: () => import(/* webpackChunkName: "about" */ "../views/wall.vue")
  },
  {
    path: "/fang",
    name: "fang",
    meta: {
      title: "益鸟维修-防水补漏"
    },
    component: () => import(/* webpackChunkName: "about" */ "../views/fang.vue")
  },
  {
    path: "/weixiu",
    name: "weixiu",
    meta: {
      title: "益鸟维修-维修安装"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/weixiu.vue")
  },
  {
    path: "/yicase",
    name: "yicase",
    meta: {
      title: "益鸟维修-益鸟案例"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/yicase.vue")
  },
  {
    path: "/casedetail",
    name: "casedetail",
    meta: {
      title: "益鸟维修-案例详情"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/casedetail.vue")
  },
  {
    path: "/team",
    name: "team",
    meta: {
      title: "益鸟维修-益鸟团队"
    },
    component: () => import(/* webpackChunkName: "about" */ "../views/team.vue")
  },
  {
    path: "/news",
    name: "news",
    meta: {
      title: "益鸟维修-新闻资讯"
    },
    component: () => import(/* webpackChunkName: "about" */ "../views/news.vue")
  },
  {
    path: "/about",
    name: "about",
    meta: {
      title: "益鸟维修-关于我们"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/newsdetail",
    name: "newsdetail",
    meta: {
      title: "益鸟维修-新闻详情"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsdetail.vue")
  },
  {
    path: "/rezhao",
    name: "rezhao",
    meta: {
      title: "益鸟维修-招聘详情"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/rezhao.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
