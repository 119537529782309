<template>
  <div class="header">
    <div
      class="flex_between flex_items p10"
      style="background: black; color: white"
    >
      <div class="flex_start flex_items f16">
        <!-- <span class="mr10">成都</span> -->
        <span>益鸟美居 房屋维修+装修改造 真自营不外包</span>
      </div>
      <div class="flex_start flex_items">
        <span class="mr5"></span>
        <wd-img
          class="mr5"
          width="22"
          height="22"
          fit="contain"
          :src="imgUrl + 'home/toubudianhua.png'"
          mode="aspectFill"
        />
        <span class="f18">4000-987-009</span>
      </div>
    </div>
    <!-- nav -->
    <div class="flex_start flex_items ptb20 bgwhite pos">
      <wd-img
        fit="contain"
        :src="imgUrl + 'home/logo.png'"
        class="logo"
        mode="aspectFill"
      />
      <div class="flex_between flex_items ml30 baitou" style="color: black;">
        <div class="mr20 flex_between">
          <span
            :class="['pb10', 'tou', '首页' == nav ? 'select' : 'noselect']"
            @click="chosenav('首页')"
            >首页</span
          >
          <span
            :class="[
              'pb10',
              'tou',

              'pos',
              nav == '产品中心' ? 'select' : 'noselect'
            ]"
            style="disblay: inline-block"
            @click="chosenav('产品中心one')"
            >产品中心
            <div
              v-if="chanxuan"
              class="abs flex_column"
              style="width: 100%; left: -16px; top: 40px; z-index: 10"
            >
              <div
                class="bbe9s plr20 ptb15 f20 f-center"
                style="color: #c3c3c3; background: black; width: 60px"
                @click.stop="chosenav('产品中心')"
              >
                <span>局部改造</span>
              </div>
              <div
                class="bbe9s plr20 ptb15 f20 f-center"
                style="color: #c3c3c3; background: black; width: 60px"
                @click.stop="chosenav('墙面翻新')"
              >
                <span>墙面翻新</span>
              </div>
              <div
                @click.stop="chosenav('防水补漏')"
                class="bbe9s plr20 ptb15 f20 f-center"
                style="color: #c3c3c3; background: black; width: 60px"
              >
                <span>防水补漏</span>
              </div>
              <div
                @click.stop="chosenav('维修安装')"
                class="bbe9s plr20 ptb15 f20 f-center"
                style="color: #c3c3c3; background: black; width: 60px"
              >
                <span>维修安装</span>
              </div>
            </div>
          </span>
          <span
            :class="['pb10', 'tou', '益鸟案例' == nav ? 'select' : 'noselect']"
            @click="chosenav('益鸟案例')"
            >益鸟案例</span
          >
        </div>

        <div class="flex_column flex_items mr20">
          <div class="flex_column flex_items" @click="xian">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
          <div
            :class="['abs', 'flex_column', isdis ? 'xian' : 'noxian']"
            style="right: 10px; bottom: 0; width: 75px"
          >
            <div
              v-for="(item, index) in navs2"
              :key="index"
              class="bbe9s plr20 ptb15 f20 f-center"
              style="color: #c3c3c3; background: black; width: 60px"
              @click="chosenav(item)"
            >
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "header",
  data() {
    return {
      imgUrl: this.util.imgUrl,
      navs: ["首页", "产品中心", "益鸟案例"],
      navs2: ["益鸟团队", "新闻资讯", "关于我们"],
      nav: "",
      isdis: false,
      chanxuan: false
    };
  },
  created() {
    this.nav = this.$store.state.home;
  },
  methods: {
    chosenav(item) {
      let name2 = "";
      if (item == "首页") {
        name2 = "Home";
        this.chanxuan = false;
      } else if (item == "产品中心one") {
        this.chanxuan = !this.chanxuan;
        this.isdis = false;
        return false;
      } else if (item == "产品中心") {
        name2 = "jubu";
        this.chanxuan = !this.chanxuan;
      } else if (item == "益鸟案例") {
        name2 = "yicase";
        this.chanxuan = false;
      } else if (item == "案例详情") {
        name2 = "casedetail";
        this.chanxuan = false;
      } else if (item == "墙面翻新") {
        name2 = "wall";
        this.chanxuan = false;
      } else if (item == "防水补漏") {
        name2 = "fang";
        this.chanxuan = false;
      } else if (item == "维修安装") {
        name2 = "weixiu";
        this.chanxuan = false;
      } else if (item == "益鸟团队") {
        name2 = "team";
        this.chanxuan = false;
      } else if (item == "新闻资讯") {
        name2 = "news";
        this.chanxuan = false;
      } else {
        name2 = "about";
        this.chanxuan = false;
      }
      this.isdis = false;
      this.$router.push({
        name: name2,
        params: {}
      });
    },
    xian() {
      this.chanxuan = false;
      this.isdis = !this.isdis;
    }
  },
  computed: {
    isFollow() {
      return this.$store.state.home; //需要监听的数据
    }
  },
  watch: {
    isFollow() {
      this.nav = this.$store.state.home;
    }
  }
};
</script>
<style>
.logo {
  width: 66px;
  margin-left: 10px;
}
.header .select {
  color: #5ba17f;
  border-bottom: 5px solid #5ba17f;
}
.header .tou {
  margin-right: 35px;
}
.header .tou:last-child {
  margin-right: 0 !important;
}
.header .line {
  height: 4px;
  width: 26px;
  margin-top: 6px;
  background: black;
  border-radius: 40px;
}
.header .baitou {
  /* width: 66%; */
  width: 400px;
}
.header .xian {
  visibility: visible;
  z-index: 10;
}
.header .noxian {
  visibility: hidden;
  z-index: -1;
}
</style>
